import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngxs/store';
import dayjs from 'dayjs';
import { DefaultService, KickbackRowDTO, PartnerDTO } from 'parking-sdk';
import { LineChartDataset } from 'rely-statistics-sdk';
import { Subject, takeUntil } from 'rxjs';
import { DateService } from 'src/app/core/services/date.service';
import { ExportService } from 'src/app/core/services/export.service';
import { PartnerState } from 'src/app/core/states/partner/partner.state';

@Component({
  selector: 'app-partnership',
  templateUrl: './partnership.component.html',
  styleUrls: ['./partnership.component.scss'],
})
export class PartnershipComponent {
  partner?: PartnerDTO;
  dateRangeForm?: FormGroup;
  report?: KickbackRowDTO[];
  chartData: LineChartDataset[] = [];
  chartTitles: string[] = [];
  loading = false;
  displayedColumns = [
    'bookingNumber',
    'bookingDate',
    'departureDate',
    'arrivalDate',
    'completed',
    'price',
    'discount',
  ];

  private onDestroy$ = new Subject<void>();

  constructor(
    private store: Store,
    private defaultService: DefaultService,
    private formBuilder: FormBuilder,
    private dateService: DateService,
    private exportService: ExportService
  ) {}

  ngOnInit() {
    this.dateRangeForm = this.formBuilder.group({
      from: new FormControl<Date | undefined>(
        dayjs()
          .add(-31, 'day')
          .hour(0)
          .minute(0)
          .second(1)
          .millisecond(0)
          .toDate()
      ),
      to: new FormControl<Date | undefined>(
        dayjs().toDate()
      ),
    });

    this.store
      .select(PartnerState.currentPartner)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((partner) => {
        if (partner?.partnerId) {
          this.partner = partner;
          this.getReport();
        } else {
          this.partner = undefined;
        }
      });

    this.dateRangeForm.controls['from'].valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        /* Only set to date to undefined if it is not already undefined. */
        if (this.dateRangeForm?.controls['to'].value)
          this.dateRangeForm?.controls['to'].setValue(undefined, {
            emitEvent: false,
          });
        this.getReport();
      });

    this.dateRangeForm.controls['to'].valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.getReport();
      });
  }

  getReport() {
    if (
      this.partner?.partnerId &&
      this.dateRangeForm &&
      this.dateRangeForm.controls['from'].value &&
      this.dateRangeForm.controls['to'].value
    ) {
      this.chartData = [];
      this.chartTitles = [];
      this.loading = true;
      this.defaultService
        .getPartnerBookingReport(
          this.partner.partnerId,
          dayjs(this.dateRangeForm.controls['from'].value)
          .hour(0)
          .minute(0)
          .second(0)
          .millisecond(0)
          .toDate(),
        dayjs(this.dateRangeForm.controls['to'].value)
          .hour(0)
          .minute(0)
          .second(0)
          .millisecond(0)
          .add(1, 'day')
          .toDate(),
        )
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((report) => {
          this.report = report;
          this.getChartData();
        });
    }
  }

  getChartData() {
    if (
      this.partner?.partnerId &&
      this.dateRangeForm &&
      this.dateRangeForm.controls['from'].value &&
      this.dateRangeForm.controls['to'].value
    ) {
      this.defaultService
        .getPartnerBookingAggregatedReport(
          this.partner.partnerId,
          dayjs(this.dateRangeForm.controls['from'].value)
          .hour(0)
          .minute(0)
          .second(0)
          .millisecond(0)
          .toDate(),
        dayjs(this.dateRangeForm.controls['to'].value)
          .hour(0)
          .minute(0)
          .second(0)
          .millisecond(0)
          .add(1, 'day')
          .toDate(),
        )
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((data) => {
          let sumPriceComplete: number[] = [];
          let sumPriceIncomplete: number[] = [];

          data.forEach((row) => {
            row?.date &&
              this.chartTitles.push(this.formatDate(row.date).toString());
            sumPriceComplete.push(row.sumPriceComplete || 0);
            sumPriceIncomplete.push(
              (row.sumPriceIncomplete || 0) + (row.sumPriceComplete || 0)
            );
          });

          this.chartData.push(
            {
              data: sumPriceComplete,
              type: 'line',
              name: 'Utnyttjade bokningar',
            },
            {
              data: sumPriceIncomplete,
              type: 'line',
              name: 'Alla bokningar',
            }
          );
          this.loading = false;
        });
    }
  }

  formatDate(date: Date): Date | string {
    return this.dateService.formatDate(date);
  }

  formatTime(date: Date): Date | string {
    return this.dateService.formatTime(date);
  }

  getTotalByType(type: 'price' | 'discount' | 'amountPaid'): number {
    return (
      this.report
        ?.map((t) => t[type])
        .reduce((acc, value) => (acc || 0) + (value || 0), 0) || 0
    );
  }

  getTotalBookings() {
    return this.report?.length;
  }

  getTotalCompleted() {
    return this.report?.filter((row) => row.completed).length;
  }

  downloadReportBookings() {
    if (
      this.partner?.partnerId &&
      this.dateRangeForm &&
      this.dateRangeForm.controls['from'].value &&
      this.dateRangeForm.controls['to'].value
    )
      this.exportService.getReportPartnerBookings(
        dayjs(this.dateRangeForm.controls['from'].value)
          .hour(0)
          .minute(0)
          .second(0)
          .millisecond(0)
          .toDate(),
        dayjs(this.dateRangeForm.controls['to'].value)
          .hour(0)
          .minute(0)
          .second(0)
          .millisecond(0)
          .add(1, 'day')
          .toDate(),
        this.partner.partnerId
      );
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
