import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import dayjs from 'dayjs';
import { ArticleReportDTO, DefaultService } from 'parking-sdk';
import { Subject, takeUntil } from 'rxjs';
import { ExportService } from 'src/app/core/services/export.service';

@Component({
  selector: 'app-daily-article-report',
  templateUrl: './daily-article-report.component.html',
  styleUrls: ['./daily-article-report.component.scss'],
})
export class DailyArticleReportComponent {
  articleReport?: ArticleReportDTO[];
  from = new FormControl<Date | undefined>(
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate(),
      0,
      0,
      0
    )
  );
  to = new FormControl<Date | undefined>(
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate(),
      0,
      0,
      0
    )
  );
  loading = false;
  errorMessage = false;

  private onDestroy$ = new Subject<void>();

  constructor(
    private defaultService: DefaultService,
    private exportService: ExportService
  ) {}

  ngOnInit() {
    this.getArticleReport();

    this.from.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        if (this.to.value) {
          this.to.setValue(undefined, {
            emitEvent: false,
          });
        }
        this.from.value && this.to?.value && this.getArticleReport();
      });

    this.to?.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        () =>
          this.from.value && this.to?.value && this.getArticleReport()
      );
  }

  getArticleReport(): void {
    if (this.from.value && this.to?.value) {
      this.loading = true;
      this.defaultService
        .getArticleReport(
          dayjs(this.from.value)
            .hour(0)
            .minute(0)
            .second(0)
            .millisecond(0)
            .toDate(),
          dayjs(this.to.value)
            .hour(0)
            .minute(0)
            .second(0)
            .millisecond(0)
            .add(1, 'day')
            .toDate()
        )
        .pipe(takeUntil(this.onDestroy$))
        .subscribe({
          next: (value) => {
            this.articleReport = value;
            this.loading = false;
            this.errorMessage = false;
          },
          error: (error) => {
            console.error('Error fetching report: ', error);
            this.errorMessage = true;
            this.loading = false;
          },
        });
    } else {
      this.loading = false;
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  downloadReport() {
    if (this.from.value && this.to?.value)
      this.exportService.getReportArticles(
        dayjs(this.from.value)
          .hour(0)
          .minute(0)
          .second(0)
          .millisecond(0)
          .toDate(),
        dayjs(this.to.value)
          .hour(0)
          .minute(0)
          .second(0)
          .millisecond(0)
          .add(1, 'day')
          .toDate()
      );
  }

  getTotalByType(type: 'payments' | 'refunds' | 'brutto' | 'netto' | 'vat'): number | undefined {
    return (
      this.articleReport
        ?.map((t) => t[type])
        .reduce((acc, value) => (acc || 0) + (value || 0), 0) || undefined
    );
  }
}
