<app-order-items [order]="order" [currentBooking]="currentBooking"
  (onGetBooking)="getBooking($event)"></app-order-items>

<div class="payment-summary">
  <p class="original-price">
    <span>Ordinarie pris: </span>
    <span>
      {{ (order?.totalAmountWithoutDiscount | number : "1.0-0") || 0 }}
      kr
    </span>
  </p>
  <p class="discount">
    <span>Total rabatt: </span>
    <span>{{calculateTotalDiscount() !== 0 ? '-' : ''}}{{ calculateTotalDiscount() | number : "1.0-0" }} kr</span>
  </p>
  <p class="discount">
    <span>Betalat belopp: </span><span>{{ (order?.paidAmount | number : "1.0-0") || 0 }} kr</span>
  </p>
  <p class="discount" *ngIf="order?.refundedAmount">
    <span>Återbetalat belopp: </span>
    <span>-{{ (order?.refundedAmount | number : "1.0-0") || 0}} kr</span>
  </p>
  <p class="total-amount" *ngIf="order?.amountToPay !== undefined">
    <span>{{(order?.amountToPay || 0) >= 0 ? 'Kvar att betala: ' : 'Kvar att återbetala: '}} </span>
    <span>{{ (order?.amountToPay | number : "1.0-0") || 0 }} kr</span>
  </p>
</div>

<div class="order-actions">
  <button class="button  secondary summary-btn" title="Skriv ut ordersummering"
    (click)="reloadReceipt(); printSummary()" [disabled]="cancelledBookings">
    Summering
  </button>

  <div class="buttons-container">
    <button class="button" [ngClass]="{'unfinished-payments': hasUnfinishedPayments, 'warning': hasUnfinishedPayments}"
      (click)="goToCheckout()"
      [title]="hasUnfinishedPayments ? 'Gå till hantera påbörjade betalningar':'Gå till hantera betalning'"
      [disabled]="cancelledBookings">
      {{hasUnfinishedPayments ? 'Hantera påbörjade betalningar ' : 'Hantera betalning'}}
      <mat-icon *ngIf="hasUnfinishedPayments">warning</mat-icon>
    </button>
    <button *ngIf="!hasOnlyPaymentType('KLARNA') &&
     !hasOnlyPaymentType('INVOICE') &&
      (order?.payments!.length > 0 ||
      zeroPayment)" class="button slim" title="Skriv ut kvitto" (click)="printReceipt()"
      [disabled]="cancelledBookings">
      Kvittokopia
    </button>
    <button *ngIf="hasPaymentType('KLARNA')" [ngClass]="{'button': true, 'slim': true, 'loading': loading}"
      (click)="sendKlarnaReceipt()" title="Skicka e-post med Klarna-kvitto" [disabled]="cancelledBookings">
      Skicka Klarna-kvittokopia
      <mat-spinner *ngIf="loading" diameter="25" color="accent"></mat-spinner>
    </button>
  </div>
</div>
<p [ngClass]="{'email-msg': true, 'success': emailSent, 'failed': !emailSent}" *ngIf="emailMsg">{{emailMsg}}</p>

<!-- Receipt -->
<iframe #iframereceipt id="iframereceipt" name="iframereceipt" *ngIf="order">
</iframe>
<iframe #iframesummary id="iframesummary" name="iframesummary" (load)="onLoad()" *ngIf="order">
</iframe>