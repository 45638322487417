<div class="daily-payment-method-report-wrapper">
    <h2>Dagsrapport - betalmetod</h2>

    <!-- Datepicker -->
    <div class="date-and-export">
        <mat-form-field>
            <mat-label>Välj period</mat-label>
            <mat-date-range-input [rangePicker]="picker">
                <input matStartDate placeholder="Startdatum" [formControl]="from">
                <input matEndDate placeholder="Slutdatum" [formControl]="to">
            </mat-date-range-input>
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>

        <button (click)="downloadReport()" class="button slim secondary"
            title="Exportera till CSV">Exportera<mat-icon>save_alt</mat-icon></button>
    </div>

    <!-- Table -->
    <div>
        <table mat-table [dataSource]="paymentMethodReport" *ngIf="paymentMethodReport">
            <ng-container matColumnDef="article">
                <th mat-header-cell *matHeaderCellDef>Betalmetod</th>
                <td mat-cell *matCellDef="let report">{{report.article}}</td>
                <td mat-footer-cell *matFooterCellDef><span *ngIf="paymentMethodReport.length">Total</span> </td>
            </ng-container>

            <ng-container matColumnDef="payments">
                <th mat-header-cell *matHeaderCellDef class="number-data">Betalningar</th>
                <td mat-cell *matCellDef="let report" class="number-data">{{report.payments | number: "1.0-0"}} kr</td>
                <td mat-footer-cell *matFooterCellDef class="number-data">{{getTotalByType('payments') | number:
                    "1.0-0"}} <span *ngIf="paymentMethodReport.length">kr</span></td>
            </ng-container>

            <ng-container matColumnDef="refunds">
                <th mat-header-cell *matHeaderCellDef class="number-data">Återköp</th>
                <td mat-cell *matCellDef="let report" class="number-data">{{report.refunds | number: "1.0-0"}} kr</td>
                <td mat-footer-cell *matFooterCellDef class="number-data">{{getTotalByType('refunds') | number:
                    "1.0-0"}} <span *ngIf="paymentMethodReport.length">kr</span></td>
            </ng-container>

            <ng-container matColumnDef="total">
                <th mat-header-cell *matHeaderCellDef class="number-data">Total</th>
                <td mat-cell *matCellDef="let report" class="number-data">{{report.total | number: "1.0-0"}} kr</td>
                <td mat-footer-cell *matFooterCellDef class="number-data">{{getTotalByType('total') | number: "1.0-0"}}
                    <span *ngIf="paymentMethodReport.length">kr</span></td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="['article', 'payments', 'refunds', 'total']"></tr>
            <tr mat-row *matRowDef="let row; columns: ['article', 'payments', 'refunds', 'total']"></tr>
            <tr mat-footer-row *matFooterRowDef="['article', 'payments', 'refunds', 'total']"></tr>
        </table>
    </div>

    <!-- Empty set info -->
    <div *ngIf="(paymentMethodReport?.length || 0) <= 0" class="{{!errorMessage ? 'empty-content' : 'error-content'}}">
        <p>{{errorMessage ? 'Någonting gick fel, kunde inte hämta data för den valda perioden.' :
            'Det finns inga data för den valda perioden.'}}</p>
    </div>
</div>