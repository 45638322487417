<!-- Back button -->
<button mat-flat-button id="backButton" [routerLink]="['/hem']">
  <mat-icon>arrow_back</mat-icon> Till startsidan
</button>

<section class="order-checkout">
  <h2>{{ paymentFinished ? 'Order genomförd' : 'Hantera betalning' }}</h2>
  <h3>Order #{{ currentOrder.orderId }}</h3>

  <!-- Order object summary -->
  <div class="order-summary">
    <div *ngFor="let item of currentOrder.orderItems">
      <div class="item-spec">
        <span *ngIf="item.orderItemType === 'FEATURE'" class="label"><strong>{{ item.feature?.name }}</strong>
          (Tilläggstjänst)</span>

        <ng-container *ngIf="item.orderItemType === 'RESOURCE'">
          <span class="label"><strong>{{ item.booking?.registrationNumber }}</strong>
            (Bokning)
          </span>
        </ng-container>

        <span *ngIf="item.orderItemType === 'PREPAIDTICKET'" class="label">
          <strong>{{ item.prepaidTicket?.name }}</strong> (Abonnemang)
        </span>

        <span class="flex">
          <strong class="item-amt">{{ item.amount }} kr</strong>
          <button *ngIf="!paymentFinished && allowPayments" class="button slim success discount-btn"
            (click)="setDiscountItem(item)" matTooltip="Rabatt">
            <mat-icon class="icon-display">local_offer</mat-icon>
          </button>
        </span>
      </div>

      <div class="item-spec discount-item" *ngIf="item.discount">
        <span>Rabatt: {{item.discountComment}}</span>
        <span class="flex">
          <p>-{{item.discount}} kr</p><mat-icon *ngIf="!hideRemoveDiscount(item) && !paymentFinished && allowPayments"
            class="remove-discount" (click)="removeDiscount(item)">clear</mat-icon>
        </span>
      </div>

      <!-- Discount -->
      <form *ngIf="discountedItem === item && discountForm" [formGroup]="discountForm" class="item-spec">
        <mat-form-field class="normal-input">
          <mat-label>Belopp</mat-label>
          <input matInput type="number" formControlName="discountAmount" name="discountAmount" [max]="item.amount || 0">
          <mat-error>Max rabatt {{item.amount}} kr</mat-error>
        </mat-form-field>

        <mat-form-field class="wide-input">
          <mat-label>Motivering</mat-label>
          <input matInput type="text" formControlName="discountComment" name="discountComment">
        </mat-form-field>
        <button class="button slim success discount-button" (click)="applyDiscount()" matTooltip="Applicera rabatt"
          [disabled]="!discountForm.valid">Applicera</button>
      </form>
    </div>
  </div>

  <!-- Order price summary -->
  <div class="total-wrapper">
    <p>
      <span>Ordinarie pris: </span><span>{{ currentOrder.totalAmountWithoutDiscount }} kr</span>
    </p>
    <p *ngIf="currentOrder.discount"><span>Rabatt: </span><span>-{{currentOrder.discount}} kr</span></p>
    <p *ngIf="currentOrder.paidAmount"><span>Betalat belopp:</span> <span>{{ currentOrder.paidAmount }} kr</span></p>
    <p class="total-amount">
      <span>{{refund ? 'Att återbetala: ' : 'Att betala: '}}</span><span>{{ currentOrder.amountToPay }} kr</span>
    </p>
  </div>

  <!-- Payment options -->
  <div class="radio-buttons" *ngIf="!paymentFinished && !zeroCheckout && !refund && allowPayments">
    <h4>Betalningsmetod</h4>
    <form [formGroup]="paymentMethod">
      <mat-radio-group formControlName="method" name="method">
        <mat-radio-button [value]="'Kort'">Kort</mat-radio-button>
        <mat-radio-button [value]="'Kontant'">Kontant</mat-radio-button>
        <mat-radio-button [value]="'Betala senare'">Betala senare</mat-radio-button>
        <mat-radio-button [value]="'Faktura'">Faktura</mat-radio-button>
      </mat-radio-group>
    </form>
  </div>

  <div class="selected-payment-method" *ngIf="paymentFinished && this.paymentMethod.controls['method'].value">
    <p><strong>Vald betalningsmetod:</strong> {{this.paymentMethod.controls['method'].value}}</p>
  </div>

  <!-- Unfinished started payments -->
  <app-unfinished-payments *ngIf="startedPayments?.length" [startedPayments]="startedPayments"
    (continuePayment)="continuePayment($event)"></app-unfinished-payments>

  <!-- Invoice information -->
  <div class="invoice-wrapper" *ngIf="!paymentFinished">
    <mat-expansion-panel [expanded]="paymentMethod.controls['method'].value === 'Faktura'" hideToggle>
      <div class="invoice-form-wrapper">
        <h4>Fakturauppgifter</h4>
        <form *ngIf="invoiceForm" [formGroup]="invoiceForm" spellcheck="false" autocomplete="off">
          <h5>Företagsuppgifter</h5>
          <div class="form-fieldset organization-and-vat">
            <mat-form-field>
              <mat-label>Organisations-/personnummer</mat-label>
              <input matInput name="organizationNumber" formControlName="organizationNumber"
                (change)="updateInvoice()" />
              <mat-error>Måste vara mellan 10 - 12 tecken</mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Referens</mat-label>
              <input matInput name="reference" formControlName="reference" (change)="updateInvoice()" />
            </mat-form-field>
          </div>

          <mat-form-field class="name-field">
            <mat-label>Namn</mat-label>
            <input matInput name="name" formControlName="name" (change)="updateInvoice()" />
          </mat-form-field>

          <h5>Kontaktuppgifter</h5>
          <div class="form-fieldset">
            <mat-form-field>
              <mat-label>E-post</mat-label>
              <input matInput name="email" formControlName="email" (change)="updateInvoice()" />
            </mat-form-field>

            <mat-form-field>
              <mat-label>Telefonnummer</mat-label>
              <input matInput name="phone" formControlName="phone" (change)="updateInvoice()" />
            </mat-form-field>
          </div>

          <h5>Faktureringsadress</h5>
          <div class="form-fieldset e-invoice-set">
            <mat-checkbox class="e-invoice-check" name="eInvoiceCheck" [formControl]="eInvoiceCheck" color="accent"
              (change)="switchInvoiceFormReq()">
              e-faktura
            </mat-checkbox>
            <mat-form-field class="e-invoice-address">
              <mat-label>E-post</mat-label>
              <input matInput name="invoiceEmail" formControlName="invoiceEmail" (change)="updateInvoice()" />
            </mat-form-field>
          </div>
          <div class="form-fieldset address-and-zip">
            <mat-form-field class="address-field">
              <mat-label>Adress</mat-label>
              <input matInput name="address" formControlName="address" (change)="updateInvoice()" />
            </mat-form-field>

            <mat-form-field class="zip-field">
              <mat-label>Postnummer</mat-label>
              <input matInput name="zip" formControlName="zip" (change)="updateInvoice()" />
            </mat-form-field>
          </div>

          <div class="form-fieldset">
            <mat-form-field>
              <mat-label>Stad</mat-label>
              <input matInput name="city" formControlName="city" (change)="updateInvoice()" />
            </mat-form-field>

            <mat-form-field>
              <mat-label>Land</mat-label>
              <input matInput name="country" formControlName="country" (change)="updateInvoice()" />
            </mat-form-field>
          </div>
        </form>
      </div>
    </mat-expansion-panel>
  </div>

  <div *ngIf="invalidFormMessage" class="invalid-form-message">
    <mat-icon>error</mat-icon> <span>{{ invalidFormMessage }}</span>
    <mat-icon>error</mat-icon>
  </div>

  <div class="checkout-buttons to-booking" *ngIf="paymentFinished">
    <button *ngIf="!containsOnlyPrepaid()" class="button slim success" [disabled]="getBookingId() === undefined"
      [routerLink]="['/bokning', getBookingId()]">
      Gå till detaljsidan
    </button>
    <button *ngIf="containsOnlyPrepaid()" class="button slim success" [routerLink]="['/abonnemang']">
      Gå till abonnemang
    </button>
    <button [ngClass]="{'button': true, 'slim': true, 'success': true, 'loading': loading}" *ngIf="!newOrder"
      (click)="sendEmail()">
      Skicka bokningsbekräftelse
      <mat-spinner *ngIf="loading" diameter="25" color="accent"></mat-spinner>
    </button>
    <p [ngClass]="{'confirmation-msg': true, 'success': emailSent, 'failed': !emailSent}" class="confirmation-msg"
      *ngIf="!newOrder && emailMsg">{{emailMsg}}</p>
  </div>

  <div class="checkout-buttons" *ngIf="!paymentFinished && allowPayments">
    <button *ngIf="refund" class="button success" (click)="handleRefund()">Hantera återbetalning</button>
    <button *ngIf="refund" class="button success" (click)="payLater(currentOrder)">Betala senare</button>
    <button *ngIf="newOrder && !refund" class="button slim warning" (click)="cancelOrder()">
      Makulera order
    </button>
    <button *ngIf="!zeroCheckout && !refund" class="button success" (click)="handlePayment()"
      [disabled]="!paymentMethod.controls['method'].value">
      {{
      paymentMethod.controls['method'].value === 'Faktura' ||
      paymentMethod.controls['method'].value === 'Betala senare'
      ? 'Spara'
      : 'Starta betalning'
      }}
    </button>
    <button *ngIf="zeroCheckout && !refund" class="button success" [ngClass]="{'loading': loading}"
      (click)="handlePayment()">
      Slutför order
      <mat-spinner *ngIf="loading" diameter="25" color="accent"></mat-spinner>
    </button>
  </div>
</section>

<!-- Receipt -->
<iframe #iframe id="iframe" name="iframe">
</iframe>